import React from "react";
import Slide from "react-reveal/Slide";
import SvgPaper from "../../assets/img/section-sign/paper.svg";
import SvgHand from "../../assets/img/section-sign/mano.svg";
import SvgPhone from "../../assets/img/section-sign/firma.svg";

export const SectionSignInfo = () => {
  return (
    <Slide left>
      <div className="container-fluid" id="sign-info">
        <div className="row g-0 display-lg">
          <div className="row card-auto">
            <div className="col-4 card-container">
              <div className="card box1">
                <img src={SvgPaper} className="card-img-top mx-auto" />
                <div className="card-body">
                  <p className="card-text text-center">
                    <b>Varios tipos de firma</b>
                    <br /> disponibles de acuerdo
                    <br /> a lo que necesitas
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4 card-container">
              <div className="card box2">
                <img src={SvgHand} className="card-img-top mx-auto" />
                <div className="card-body">
                  <p className="card-text text-center">
                    <b> Tu firma electrónica </b> <br />
                    con validez legal al alcance <br />
                    de un click
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4 card-container">
              <div className="card box3">
                <img src={SvgPhone} className="card-img-tablet" />
                <div className="card-body">
                  <p className="card-text text-center">
                    <b>Firma fácil y rápido</b>
                    <br />
                    desde cualquier dispositivo
                    <br />
                    usando DecSigner
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row display-xs">
          <div className="col-11 mx-auto">
            <div className="card box1">
              <div className="row g-0">
                <div className="col-7">
                  <p className="text-1">
                    <b>Varios tipos de firma</b>
                    <br /> disponibles de acuerdo
                    <br /> a lo que necesitas
                  </p>
                </div>
                <div className="col-5">
                  <img
                    src={SvgPaper}
                    className="card-img-top-document mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-11 mx-auto">
            <div className="card box2">
              <div className="row">
                <div className="col-7 order-1">
                  <p className="text-2">
                    <b> Tu firma electrónica</b> con
                    <br /> validez legal al alcance de
                    <br /> un click
                  </p>
                </div>
                <div className="col-5 container-box order-0">
                  <img src={SvgHand} className="card-img-top-mano mx-auto" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-11 mx-auto">
            <div className="card box3">
              <div className="row">
                <div className="col-7">
                  <p className="text-1">
                    <b>
                      Firmado seguro y <br /> rápido
                    </b>{" "}
                    desde cualquier <br />
                    dispositivo usando <br />
                    DecSigner 
                  </p>
                </div>
                <div className="col-5">
                  <img src={SvgPhone} className="card-img-top-firma mx-auto" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};
