import React from "react";
import { Image } from "react-bootstrap";
import { Bounce, Slide } from "react-reveal";
import "animate.css/animate.css";

import arrowLeftMobil from "../../assets/img/arrow-mobil-left.svg";
import arrowLeftTable from "../../assets/img/arrow-left-tablet.png";
import arrowTop from "../../assets/img/arrow-vertical.svg";
import ArrowLeft from "../../assets/img/arrow-left.svg";
import ArrowRight from "../../assets/img/arrow-right.svg";
import MainImage from "../../assets/img/mobil-digital.svg";
import { useMediaQuery } from "react-responsive";

export const SectionsDigital = () => {
  const isMobile = useMediaQuery({ maxWidth: 425 });
  const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 768 });
  return (
    <section id="section-2" className="container-fluid">
      <div className="row g-0">
        <div className="col-5">
          <Slide left>
            <img
              className="body-arrow"
              src={
                isMobile
                  ? arrowLeftMobil
                  : isTablet
                  ? arrowLeftTable
                  : ArrowLeft
              }
              alt=""
            />
          </Slide>
        </div>
      </div>
      <div className="row row-dec g-0 d-flex align-items-center">
        <div className="col-sm-6 col-12 order-sm-0 order-1 align-items-center">
          <Bounce left>
            <div className="text-center">
              <Image className="body-image" src={MainImage} />
            </div>
          </Bounce>
        </div>
        <div className="col-sm-6 col-12 order-sm-1 order-0  align-items-center">
          <Bounce right>
            <h1>
              Tus documentos <b>en la nube,</b> listos para ser firmados
            </h1>
            <h2 className="text-lg">
              Ahorra tiempo digitalizando tus procesos 
            </h2>
            <h2 className="text-sm">
              Ahorra tiempo digitalizando tus procesos 
            </h2>
            <h2 className="text-xs" style={{ marginTop: "22%" }}>
              Ahorra tiempo <br />
              digitalizando tus procesos 
            </h2>
            <p className="text-lg">
              Sube, envía y firma tus documentos en cuestión de minutos.
              <br />
              <b> ¡Con Dec rompe el récord!</b>
            </p>
            <p className="text-sm">
              Sube, envía y firma tus documentos en cuestión de minutos.
              <b> ¡Con Dec rompe el récord!</b>
            </p>
            <p className="text-xs">
              Sube, envía y firma tus <br /> documentos en cuestión
              <br /> de minutos.
              <br />
              <b> ¡Con Dec rompe el récord!</b>
            </p>
            <br />
            <h2 className="text-lg">
              DecSigner: firma segura a cualquier hora,
              <br /> en cualquier lugar
            </h2>
            <p className="text-lg">
              En la oficina, la casa o la playa <b>¡tú eliges!</b> <br />
              Conéctate al instante desde tu dispositivo favorito.
            </p>
            <h2 className="text-sm">
              DecSigner: firma segura a cualquier hora,
              <br /> en cualquier lugar
            </h2>
            <p className="text-sm">
              En la oficina, la casa o la playa <b>¡tú eliges!</b> <br />
              Conéctate al instante desde tu dispositivo favorito.
            </p>
            <h2 className="text-xs">
              DecSigner: firma segura a <br /> cualquier hora, en cualquier
              lugar
            </h2>
            <p className="text-xs">
              En la oficina, la casa o la playa
              <br /> <b>¡tú eliges!</b> <br />
              Conéctate al instante desde tu <br /> dispositivo favorito.
            </p>
          </Bounce>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-9 offset-3 mt-3">
          <Slide right>
            <Image
              className="arrow-body"
              src={isMobile ? arrowTop : ArrowRight}
              fluid
            />
          </Slide>
        </div>
      </div>
    </section>
  );
};
