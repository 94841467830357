import React from "react";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import Flip from "react-reveal/Flip";

export const SectionPlansHeader = () => {
  return (
    <Flip top>
      <section id="plans-header" className="d-flex align-items-center">
        <Container>
          <Row>
            <Col lg={12} className="mb-5 mt-5">
              <h1 className="text-center">Planes y precios Dec</h1>
            </Col>
          </Row>

          <Row>
            <Col lg={{ span: 6, offset: 3 }} xs={{ span: 10, offset: 1 }}>
              <div className="text-center container-btn-header">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">¡Próximamente!</Tooltip>}
                >
                  <div className="button-1">Personal</div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">¡Próximamente!</Tooltip>}
                >
                  <div className="button-2">Enterprise</div>
                </OverlayTrigger>

                <div className="button-3">Developers</div>
              </div>
            </Col>

            <Col lg={{ span: 4, offset: 4 }} className="mt-5">
              <div className="text-center">
                <a className="download-btn2">Anual</a>
              </div>
              {/*   Botones ocultos<div className="button-4">Mensual</div><div className="button-5">Anual</div>*/}
            </Col>
          </Row>
        </Container>
      </section>
    </Flip>
  );
};
