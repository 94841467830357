import React from "react";
import { Image } from "react-bootstrap";
import { Slide } from "react-reveal";
import imageLeft from "../../assets/img/section-rapido/cohete.svg";
import arrowRight from "../../assets/img/section-rapido/arrow-right-footer.svg";
import arrowLeft from "../../assets/img/section-firma-rapida/arrow-left.svg";
import useViewport from "../../components/GridResponsive/ViewResponsive";
export const SectionRapida = () => {
  const { width, breakpoint } = useViewport();
  return (
    <section
      id="section-4"
      className="container-fluid"
      style={{ marginBottom: width < breakpoint ? "20px" : "" }}
    >
      <div className="row g-0">
        <div className="col-7 body-arrow-left">
          <Slide left>
            <Image src={arrowLeft} fluid />
          </Slide>
        </div>
      </div>
      <div className="row row-dec g-0 d-flex align-items-center">
        <div className="col-sm-6 col-5 body-container-img">
          <Slide left>
            <Image src={imageLeft} fluid />
          </Slide>
        </div>
        <div className="col-sm-6 col-7">
          <Slide right>
            <h1>
              <b>Rápido</b> y en la nube
            </h1>
            <p className="text-lg">
              Olvídate de pérdidas, daños o robos.
              <br /> Almacena y protege todos tus procesos
              <br /> en un storage personal.
              <br /> Accede en cualquier momento.
            </p>
            <p className="text-lg">
              <b>¡Hazlo sencillo, hazlo seguro! </b>
            </p>
            <p className="text-sm">
              Olvídate de pérdidas, daños o robos.
              <br /> Almacena y protege todos tus procesos
              <br /> en un storage personal.
              <br /> Accede en cualquier momento.
              <br />
              <b>¡Hazlo sencillo, hazlo seguro! </b>
            </p>
            <p className="text-xs">
              Olvídate de pérdidas, daños
              <br /> o robos.
              <br /> Almacena y protege todos
              <br /> tus procesos en un storage
              <br /> personal. <br />
              Accede en cualquier
              <br /> momento.
              <b>
                {" "}
                ¡Hazlo sencillo,
                <br /> hazlo seguro! 
              </b>
            </p>
          </Slide>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-8 offset-4 body-container-arrow ">
          <Slide right>
            <Image src={arrowRight} fluid />
          </Slide>
        </div>
      </div>
    </section>
  );
};
