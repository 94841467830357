import React from "react";
import { Slide } from "react-reveal";
import imagenLeft from "../../assets/img/section-sign/team.svg";
import signFooter from "../../assets/img/section-sign/logofooter.svg";

export const SectionSignFooter = () => {
  return (
    <>
      <div className="container-fluid" id="sign-footer">
        <div className="row row-dec g-0">
          <div className="col-12 mx-auto">
            <div className="row d-flex align-items-top">
              <div className="col-sm-6 order-sm-1 order-1 img-footer-left text-end">
                <div className="row">
                  <Slide left>
                  <div className="col-12 text-sm-end text-center">
                    <img src={imagenLeft} alt="" />
                  </div>
                  </Slide>
                </div>
              </div>
              <Slide right>
              <div className="col-sm-6 col-12 order-sm-1 order-0 text-start img-footer-right">
                <div className="row">
                  <div className="col-12 text-center">
                    <img src={signFooter} alt="" />
                  </div>
                  <div className="col-12 container-backgraund">
                    <h2 className="text-center">
                      El complemento perfecto de Dec
                    </h2>
                  </div>
                  <div className="col-12 text-center">
                    <a
                      href={process.env.REACT_APP_BTN_PRUEBA_DEC}
                      className="btn-get-started"
                    >
                     Prueba Dec
                    </a>
                  </div>
                </div>
              </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
