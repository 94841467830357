import React from "react";
import { Image } from "react-bootstrap";
import { Slide } from "react-reveal";
import imageRigth from "../../assets/img/section-firma-rapida/hoja-logo.svg";

export const SectionFirmaRapida = () => {
  return (
    <section id="section-3" className="container-fluid">
      <div className="row row-dec g-0 d-flex align-items-center">
        <Slide left>
          <div className="col-sm-6 col-8 mx-auto">
            <h1>
              <b>Firma rápida y segura</b>
              <br /> con validez legal
            </h1>
            <p className="text-lg">
              Dec es compatible con todos los tipos de firmas autorizadas:
              <br /> firma simple, firma acreditada y aceptación simple.
            </p>
            <p className="text-sm">
              Dec es compatible con todos los tipos
              <br /> de firmas autorizadas:
              <br /> firma simple, firma acreditada <br />y aceptación simple.
            </p>
            <p className="text-xs">
              Dec es compatible con todos
              <br /> los tipos de firmas autorizadas:
              <br /> firma simple, firma acreditada <br />y aceptación simple.
            </p>
          </div>
        </Slide>
        <div className="col-sm-6 col-4 text-center body-container-img-right">
          <Slide right>
            <Image src={imageRigth} fluid />
          </Slide>
        </div>
      </div>
    </section>
  );
};
