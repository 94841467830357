import React from "react";
import { Image } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Bounce } from "react-reveal";
import { useMediaQuery } from "react-responsive";

import recurso1 from "../../assets/img/section-carousel/recurso-1.svg";
import recurso2 from "../../assets/img/section-carousel/recurso-2.svg";
import recurso3 from "../../assets/img/section-carousel/recurso-3.svg";
import recurso4 from "../../assets/img/section-carousel/recurso-4.svg";
import item1Mobil from "../../assets/img/section-carousel/recurso-mobil-1.svg";
import item2Mobil from "../../assets/img/section-carousel/recurso-mobil-2.svg";
import item3Mobil from "../../assets/img/section-carousel/recurso-mobil-3.svg";
import item4Mobil from "../../assets/img/section-carousel/recurso-mobil-4.svg";
import imgPrev from "../../assets/img/section-carousel/prev.png";
import imgNext from "../../assets/img/section-carousel/next.png";

export const SectionCarousel = () => {
  const isMobile = useMediaQuery({ maxWidth: 425 });
  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  };
  return (

    <div className="container-fluid" id="carousel">
      <div className="row g-0">
      <Bounce right>
        <h1 className="text-center">
          ¡Conoce más sobre <b>Dec!</b>
        </h1>
        </Bounce>
      </div>
      <div className="row g-0  body-container">
        <div className="col-12 pt-2">
          <OwlCarousel
            items={1}
            className="owl-theme"
            loop
            nav
            dots
            margin={8}
            autoplay
            responsive={responsive}
            navText={[
              "<img class='arrow-carousel' src='" + imgPrev + "' />",
              "<img class='arrow-carousel' src='" + imgNext + "' />",
            ]}
          >
            <div className="col-12 body-card">
              <div className="row g-0">
                <div className="col-sm-6">
                  <Image src={isMobile ? item1Mobil : recurso1} />
                </div>
                <div className="col-sm-6 align-self-center text-sm-start text-center">
                  <h2>
                    Totalmente <b>intuitivo</b>
                  </h2>
                  <p className="text-lg">
                    Interfaz inteligente que te ayuda a gestionar <br />
                    tus procesos digitales de firmado al instante.
                  </p>
                  <p className="text-sm">
                    Interfaz inteligente que te ayuda a <br />
                    gestionar tus procesos digitales de <br />
                    firmado al instante. 
                  </p>
                  <p className="text-xs">
                    Interfaz inteligente que te ayuda a <br />
                    gestionar tus procesos digitales de <br />
                    firmado al instante. 
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 body-card">
              <div className="row g-0">
                <div className="col-sm-6">
                  <Image src={isMobile ? item2Mobil : recurso2} />
                </div>
                <div className="col-sm-6 align-self-center text-sm-start text-center">
                  <h2 className="text-lg">
                    <b>Portafolio</b> digital
                  </h2>
                  <h2 className="text-sm">
                    <b>Portafolio</b> digital
                  </h2>
                  <h2 className="text-xs">
                    <b>Portafolio digital</b>
                  </h2>
                  <p className="text-lg">
                    Controla tus procesos desde el momento
                    <br /> en que los creas y descarga tus archivos
                    <br /> firmados por todos los participantes.
                  </p>
                  <p className="text-sm">
                    Controla tus procesos desde el
                    <br /> momento en que los creas y <br />
                    descarga tus archivos firmados
                    <br /> por todos los participantes.
                  </p>
                  <p className="text-xs">
                  Controla tus procesos desde el
                    <br /> momento en que los creas y <br />
                    descarga tus archivos firmados
                    <br /> por todos los participantes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 body-card">
              <div className="row g-0">
                <div className="col-sm-6">
                  <Image src={isMobile ? item3Mobil : recurso3} />
                </div>
                <div className="col-sm-6 align-self-center text-sm-start text-center">
                  <h2>
                    <b>Dashboard</b>
                  </h2>
                  <p className="text-lg">
                    Visualiza tus procesos a detalle. <br />
                    Apóyate en las métricas y controla tu <br />
                    información importante.
                  </p>
                  <p className="text-sm">
                    Visualiza tus procesos a detalle. <br />
                    Apóyate en las métricas y controla <br />
                    tu información importante. 
                  </p>
                  <p className="text-xs">
                    Visualiza tus procesos a<br /> detalle. Apóyate en las
                    <br /> métricas y controla tu
                    <br /> información importante. 
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 body-card">
              <div className="row g-0">
                <div className="col-sm-6">
                  <Image src={isMobile ? item4Mobil : recurso4} />
                </div>
                <div className="col-sm-6 align-self-center text-sm-start text-center">
                  <h2>
                    <b>DecSigner</b>
                  </h2>
                  <p className="text-lg">
                    Visualiza, firma, crea e imprime <br />
                    tus documentos desde cualquier <br />
                    dispositivo desktop o mobile.
                  </p>
                  <p className="text-sm">
                    Visualiza, firma, crea e imprime
                    <br /> tus documentos desde cualquier <br />
                    dispositivo desktop o mobile.
                  </p>
                  <p className="text-xs">
                    Visualiza, firma, crea e<br /> imprime tus documentos
                    <br /> desde cualquier dispositivo <br /> desktop o mobile.
                  </p>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};
