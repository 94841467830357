import React from "react";
import { Bounce, Fade } from "react-reveal";
import SvgBag from "../../assets/img/section-carousel/maletin.svg";
import SvgPadLock from "../../assets/img/section-carousel/seguridad.svg";
import SvgCloud from "../../assets/img/section-carousel/nube.svg";
export const SectionFooter = () => {
  return (
    <div className="container-fluid">
      <div id="footer-carousel" className="row row-dec g-0 ">
        <div className="col-12">
          <Fade left>
            <h1 className="text-center text-xl">
              Dec es tu portafolio digital <b>definitivo</b>
            </h1>
            <h1 className="text-center text-sm">
              Dec es tu portafolio <br /> digital <b>definitivo</b>
            </h1>
            <h1 className="text-start text-xs">
              Dec es tu portafolio digital <br />
              <b>definitivo</b>
            </h1>
          </Fade>
        </div>
        <Fade left>
          <div className="col-12 contaner-card">
            <div className="row g-0 mx-auto">
              <div className="col-4  display-lg  mx-auto">
                <div className="card">
                  <img src={SvgBag} className="card-img-top mx-auto" />
                  <div className="card-body">
                    <p className="card-text">
                      <b>
                        Todos tus procesos <br /> de firma electrónica
                      </b>
                      <br /> en un solo lugar.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 display-xs mx-auto">
                <div className="card">
                  <div class="row g-0">
                    <div className="col-6 container-img-maletin">
                      <img src={SvgBag} className="card-img-center-maletin" />
                    </div>
                    <div className="col-6 container-text-maletin  ">
                      <p className="mtop-1">
                        <b>
                          Todos tus procesos <br /> de firma electrónica
                        </b>
                        <br /> en un solo lugar.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mx-auto display-lg">
                <div className="card">
                  <img src={SvgPadLock} className="card-img-top mx-auto" />
                  <div className="card-body">
                    <p className="card-text text-xl">
                      Tus firmas y procesos digitales
                      <b>
                        <br /> protegidos, legales, seguros,
                      </b>
                      <br /> con autentificación. 
                    </p>
                    <p className="card-text text-sm">
                      Tus firmas y procesos
                      <br /> digitales
                      <b>
                        protegidos,
                        <br /> legales, seguros,
                      </b>
                      <br /> con autentificación. 
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 mx-auto display-xs">
                <div className="card">
                  <div className="row g-0">
                    <div className="col-8 container-text-seguridad">
                      <p className="mtop-2">
                        Tus firmas y procesos <br /> digitales
                        <b>
                          {" "}
                          protegidos, legales, <br /> seguros,{" "}
                        </b>
                        con autentificación. 
                      </p>
                    </div>
                    <div className="col-4">
                      <img src={SvgPadLock} className="card-img-seguridad" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mx-auto display-lg">
                <div className="card">
                  <img src={SvgCloud} className="card-img-top mx-auto" />
                  <div className="card-body">
                    <p className="card-text">
                      <b>¡La nube va contigo!</b>
                      <br /> Controla, verifica y firma
                      <br /> desde cualquier lugar y hora
                      <br /> a través de tu dispositivo <br /> con conexión a
                      internet.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12  mx-auto display-xs">
                <div className="card">
                  <div className="row">
                    <div className="col-4">
                      <img src={SvgCloud} className="card-img-center-nube" />
                    </div>
                    <div className="col-8 container-text-nube">
                      <p className="mtop-3">
                        <b>¡La nube va contigo!</b>
                        <br /> Controla, verifica y firma
                        <br /> desde cualquier lugar y hora
                        <br /> a través de tu dispositivo con
                        <br /> conexión a internet.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        <Bounce bottom>
          <div className="col-12 container-backgraund">
            <h2 className="text-center">¿Listo para comenzar?</h2>
          </div>
          <div className="col-12 text-center">
            <a
              href={process.env.REACT_APP_BTN_COMIENZA_GRATIS}
              className="btn-get-started"
            >
              Pruébalo gratis
            </a>
          </div>
        </Bounce>
      </div>
    </div>
  );
};
