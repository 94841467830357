import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { AppRouter } from "./routers/AppRouter";

const App = () => {
  return (<><AppRouter /></>
  );
};

export default App;
