import React from "react";
import { Fade } from "react-reveal";
import ImgRigth from "../../assets/img/section-dec/firma-electronica.svg";
import backgraund from "../../assets/img/section-dec/arrowBackgraund.svg";

export const SectionsHeader = () => {
  return (
    <div className="containter-fluid" id="section-1">
      <div className="row">
        <div className="col-11 text-start container-flecha">
        <img src={backgraund} alt="" />
        </div>
      </div>
      <div className="row row-dec">
        <div className="col-sm-7 col-8">
          <div className="row">
          <Fade left>
            <div className="col-12 text-xl">
              <h1>Tu <b>firma electrónica <br /> y portafolio digital</b> <br /> en la nube </h1>
            </div>
            <div className="col-12 text-sm">
              <h1>Tu <b>firma electrónica y portafolio digital</b> en la nube </h1>
            </div>
            <div className="col-12 text-xs">
              <h1>Tu <b>firma electrónica <br /> y portafolio digital</b> <br /> en la nube </h1>
            </div>
            <div className="col-lg-6 col-sm-12">
              <h2>Almacena con Dec</h2>
              <p className="text-xl">
                Dales a tus procesos y documentos<br /> un ID único. <br />Espacio,
                seguridad y control en tu <br/> storage personal.
              </p>
              <p className="text-sm">
                Dales a tus procesos y documentos un ID único. <br />Espacio,
                seguridad y control en tu storage personal.
              </p>
              <p className="text-xs">
                Dales a tus procesos y documentos<br/> un ID único. <br />Espacio,
                seguridad y control<br/> en tu storage personal.
              </p>
            </div>
            <div className="col-lg-6 col-sm-12">
              <h2>Firma con Dec </h2>
              <p className="text-xl">
                Haz tus procesos eficientes, ágiles y<br/> modernos.<br />Conéctate con tu
                comunidad Dec,<br /> firma un documento o solicita que<br /> lo hagan. <b>¡No
                necesitan tener una<br /> firma creada con anterioridad!  </b>
              </p>
              <p className="text-sm">
                Haz tus procesos eficientes, ágiles y modernos.<br />Conéctate con tu
                comunidad Dec, firma un documento <br /> o solicita que lo hagan. <b>¡No
                necesitan tener una firma <br /> creada con anterioridad!  </b>
              </p>
              <p className="text-xs">
                Haz tus procesos eficientes, ágiles<br/> y modernos.<br />Conéctate con tu
                comunidad Dec,<br/> firma un documento o solicita que lo <br/> hagan.<br/> <b>¡No
                necesitan tener una firma <br /> creada con anterioridad!  </b>
              </p>
            </div>
            </Fade>
          </div>
        </div>
        <div className="col-sm-5 col-12 body-image text-sm-end text-start">
          <Fade right>
          <img src={ImgRigth} alt="" />
          </Fade>
        </div>
      </div>
    </div>
  );
};
