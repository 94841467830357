import React, { useState } from "react";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import {
  Container,
  Col,
  Collapse,
  Image,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from "react-bootstrap";
import "../../styles/nav.css";

/* import logoStupendo from "../../assets/img/logo-stupendo.png"; */
import dec from "../../assets/img/icon-1.png";
import easySign from "../../assets/img/icon-2.png";
import pay from "../../assets/img/icon-3.png";
import useViewport from "../../components/GridResponsive/ViewResponsive";
import { ReactComponent as LogoStupendo } from "../../assets/img/logo-stupendo.svg";
import { ReactComponent as LogoDec } from "../../assets/img/logo-dec.svg";
import { ReactComponent as ArrowDn } from "../../assets/img/arrowDn.svg";
export const IndexNavbar = () => {
  const { width, breakpoint, breakpointT } = useViewport();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navDropdownTitle = (
    <span className="productos-dec">
      Productos
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="23"
        viewBox="0 0 27 23"
      >
        <g>
          <g>
            <path
              fill="none"
              stroke="#3a436d"
              d="M18.47 8.376v0l-4.796 6.13a.22.22 0 0 1-.357.015l-4.78-6.146v0"
            />
          </g>
        </g>
      </svg>
    </span>
  );
  return (
    <div className="row g-0">
      <Navbar className="navbarMain nav-stupendo">
        <Navbar.Brand href={process.env.REACT_APP_HEADER_STUPENDO}>
          <LogoStupendo className="logo-stupendo" />
        </Navbar.Brand>
        <Navbar.Toggle />

    {/*     <Navbar.Collapse className="justify-content-end">
          <div id="body-collapse">
            <NavDropdown
              alignRight
              title={navDropdownTitle}
              id="collasible-nav-dropdown"
              style={{ color: "#3b4b73" }}
            >
              <NavDropdown.Item href="#action/3.1">
                <Row>
                  <Col lg={6}>
                    <p className="text-left text-dropdown-header">Cambiar a</p>
                  </Col>
                  <Col lg={6}>
                    <p className="text-right text-dropdown-header">
                      Inciar sesión
                    </p>
                  </Col>
                  <Col lg={12}>
                    <div className="text-left body-container-dropdowm-text">
                      <Link to="/">
                        <Image src={dec} style={{ width: "12%" }} /> Dec
                      </Link>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="text-left body-container-dropdowm-text">
                      <Link to="/">
                        <Image src={easySign} style={{ width: "12%" }} /> Easy
                        sign
                      </Link>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <p className="text-left text-dropdown-header">Descubrir</p>
                    <div className="text-left body-container-dropdowm-text">
                      <Link to="/">
                        <Image src={pay} style={{ width: "12%" }} /> Pay
                      </Link>
                    </div>
                  </Col>
                </Row>
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Navbar.Collapse> */}
      </Navbar>

      <Navbar
        collapseOnSelect
        className="navbar-dec"
        expand="lg"
        bg="light"
        variant="light"
      >
        <Navbar.Brand href={process.env.REACT_APP_HEADER_DEC} className="nav-container">
          <LogoDec className="logo-dec" />
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto-dec">
            <Nav.Link
              className={location.pathname === "/" && "nav-link-item-active "}
              href="/"
            >
              Resumen
            </Nav.Link>
            <Nav.Link
              className={location.pathname === "/dec" ?"nav-link-item-active nav-text-lg":"nav-text-lg"}
              href="/dec"
            >
              ¿Por qué Dec?
            </Nav.Link>
            <Nav.Link
              className={
                location.pathname === "/sign" ?"nav-link-item-active nav-text-lg":"nav-text-lg"

              }
              href="/sign"
            >
              Easy Sign
            </Nav.Link>
            <Nav.Link
              className={
                location.pathname === "/developers" && "nav-link-item-active"
              }
              href="/developers"
            >
              Developers
            </Nav.Link>
            <Nav.Link
              className={
                location.pathname === "/plans" && "nav-link-item-active"
              }
              href="/plans"
            >
              Planes
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Brand >
        <div className="body-btn-nav">
          <a
            onClick={() => setOpen(!open)}
            aria-controls="collapseMenu"
            aria-expanded={open}
            className="arrow-btn"
          >
            {!open ? (
              <ArrowDn
                style={{
                  width: "43px",
                  height: "30px",
                  transform: "rotate(180deg)",
                }}
              />
            ) : (
              <ArrowDn style={{ width: "43px", height: "30px" }} />
            )}
          </a>
          <a
            className="btn-pruebalo"
            href={process.env.REACT_APP_BTN_COMIENZA_GRATIS}
          >
            Pruébalo gratis
          </a>
        </div>
        <div className="body-btn-nav-lg">
          <a
            className="btn-pruebalo-lg"
            href={process.env.REACT_APP_BTN_COMIENZA_GRATIS}
          >
            Pruébalo gratis
          </a>
        </div>
        </Navbar.Brand>


        <Collapse in={open}>
          <div id="collapseMenu">
            <div
              className={
                location.pathname === "/"
                  ? "text-left page-active"
                  : "text-left page-inactive"
              }
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
              onClick={() => setOpen(!open)}
            >
              <Link to="/">Resumen</Link>
            </div>
            <div
              className={
                location.pathname === "/dec"
                  ? "text-left page-active"
                  : "text-left page-inactive"
              }
              style={{ marginBottom: "1rem" }}
              onClick={() => setOpen(!open)}
            >
              <Link to="/dec">¿Por qué Dec?</Link>
            </div>
            <div
              className={
                location.pathname === "/sign"
                  ? "text-left page-active"
                  : "text-left page-inactive"
              }
              style={{ marginBottom: "1rem" }}
              onClick={() => setOpen(!open)}
            >
              <Link to="/sign">Easy Sign</Link>
            </div>
            <div
              className={
                location.pathname === "/developers"
                  ? "text-left page-active"
                  : "text-left page-inactive"
              }
              style={{ marginBottom: "1rem" }}
              onClick={() => setOpen(!open)}
            >
              <Link to="/developers">Developers</Link>
            </div>
            <div
              className={
                location.pathname === "/plans"
                  ? "text-left page-active"
                  : "text-left page-inactive"
              }
              style={{ marginBottom: "1rem" }}
              onClick={() => setOpen(!open)}
            >
              <Link to="/plans">Planes</Link>
            </div>
          </div>
        </Collapse>
      </Navbar>
    </div>
  );
};
