import React from "react";
import imagenRight from "../../assets/img/section-sign/easy-sing.svg";
import Slide from 'react-reveal/Slide';
export const SectionHeaderSign = () => {
  return (
    <div className="container-fluid container-header">
      <div className="row row-dec g-0 " id="sign-header">
        <div className="col-sm-10 col-12 mx-auto">
          <div className="row d-flex align-items-center container-top">
            <Slide left>
            <div className="col-sm-6 col-12">
              <h1 className="text-start"><b>Tu firma electrónica</b><br /> fácil, segura y legal</h1>
              <p className="text-start">
                Crea con <b>Easy Sign</b> tu firma digital <br />
                autorizada y úsala en todos tus
                <br />
                procesos de Dec.
              </p>
            </div>
            </Slide>
            <Slide right>
            <div className="col-sm-6 col-12 text-center img-left">
              <img className="" src={imagenRight} alt="" />
            </div>
            </Slide>
          </div>
        </div>
      </div>
    </div>
  );
};
