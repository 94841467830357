import React from "react";
import { Slide } from "react-reveal";
import imgDev from "../../assets/img/section-developer/logo_dev.svg";

export const SectionDevHeader = () => {
  return (
    <>
      <div className="container-fluid  mHeight  background-dev">
        <div className="row row-dec g-0 mx-auto d-flex align-items-center">
          <div className="col-l2 mt2"></div>
          <Slide left>
            <div className="col-lg-7 col-sm-6 order-sm-0 col-12 order-1 img-dec text-center text-sm-start">
              <img src={imgDev} alt="" />
            </div>
          </Slide>
          <Slide right>
            <div className="col-sm-5 order-sm-1 col-12 order-0">
              <h2 className="devBanner-titulo">
                <b>APIs flexibles</b>, <br />
                seguras y potentes
              </h2>
              <p className="devBanner-parrafo text-lg">
                Integra fácilmente<br></br>
                la mejor experiencia <br></br>
                de firmado a tu aplicación <br></br>o flujo de negocio.
              </p>
              <p className="devBanner-parrafo text-sm">
                Integra fácilmente
                <br />
                la mejor experiencia de firmado <br /> a tu aplicación o flujo
                de negocio.
              </p>
              <p className="devBanner-parrafo text-xs">
                Integra fácilmente la mejor <br /> experiencia de firmado <br />
                a tu aplicación o flujo
                <br /> de negocio.
              </p>
            </div>
          </Slide>
        </div>
      </div>
    </>
  );
};
