import React from "react";
import imageCenter from "../../assets/img/section-portafolio/laptop_Dec_site2.svg";
import Slide from "react-reveal/Slide";
export const SectionPortafolio = () => {
  return (
    <div id="section-5" className="container-fluid">
      <div className="row g-0">
        <div className="col-11 col-sm-8  mx-auto">
          <div className="row">
            <div className="col-12">
              <Slide top>
                <h1 className="text-center title-lg">
                  <b>¡Empieza a digitalizar tus procesos!</b>
                </h1>
                <h1 className="text-center title-xs">
                  <b>
                    ¡Empieza a digitalizar tus
                    <br /> procesos!
                  </b>
                </h1>
              </Slide>
            </div>

            <div className="col-12">
              <Slide top>
                <h2 className="text-center text-lg">
                  Dec tiene versiones ideales para ti.
                  <br /> Adquiere tu portafolio digital personal,
                  <br /> empresarial o para desarrolladores.
                </h2>
                <h2 className="text-center text-sm">
                  Dec tiene versiones ideales para ti.
                  <br /> Adquiere tu portafolio digital personal, empresarial{" "}
                  <br /> o para desarrolladores.
                </h2>
              </Slide>
            </div>
            <div className="row g-0">
              <div className="col-11 mx-auto">
                <Slide bottom>
                  <img
                    src={imageCenter}
                    alt="image"
                    className="img-responsive"
                  />
                </Slide>
              </div>
            </div>
            <Slide top>
              <div className="col-12 container-backgraund">
                <p className="text-center">¿Listo para comenzar?</p>
              </div>
              <div className="col-12 text-center">
                <a
                  href={process.env.REACT_APP_BTN_COMIENZA_GRATIS}
                  className="btn-get-started"
                >
                  Pruébalo gratis
                </a>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </div>
    /*  <div className="container-fluid" style={styles.backgroundImg}>
      <div className="row">
        <div
          className="col-lg-12 text-center"
          style={{
            marginTop:'76px',
            paddingLeft: width < breakpoint ? "" : "",
          }}
        >
          <h1 style={styles.titlePorta}>
          ¡Empieza a digitalizar tus procesos!
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 mx-auto" style={styles.subTitlePorta}>

          <p className="text-center portafolio-title">
          Dec tiene versiones ideales para ti.
          </p>
          <p className="text-center portafolio-title">
          Adquiere tu portafolio digital personal,
          </p>
          <p className="text-center portafolio-title">
          empresarial o para desarrolladores.
          </p>
        </div>
      </div>
      <div
        className="row"
        style={{
          marginTop: width < breakpoint ? "20px"
          : (width > breakpoint && width < breakpointT ? "2rem" : "0rem"),
        }}
      >
        <div
          className="col-lg-8 col-xs-8 mx-auto text-center"
          style={{
            marginTop: width < breakpoint ? ""
            : (width > breakpoint && width < breakpointT ? "" : "5rem"),
          }}
        >
          <img
            src={width < breakpoint?imageCenterMobil: imageCenter}
            alt=""
            style={{
              width: width < breakpoint ? "89%"
              : (width > breakpoint && width < breakpointT ? "50%" : "89%"),
              display: width > breakpoint && width < breakpointT ? "block" : "",
              marginLeft: width > breakpoint && width < breakpointT ? "auto"
              : "",
              marginRight: width > breakpoint && width < breakpointT ? "auto"
              : "",
            }}
          />
        </div>
      </div>
      <div
        className="row"
        style={{
          marginTop: width > breakpoint && width < breakpointT ? "10%" : "63px",
        }}
      >
        <div className="col-lg-6 mx-auto mb-1" style={styles.backgroundInReady}>
          <p style={styles.secondText}>¿Listo para probarlo?</p>
        </div>
      </div>
      <div className="row text-center">
        <div
          className="col-lg-12"
          style={{
            marginBottom: width < breakpoint ? "77px"
            : (width > breakpoint && width < breakpointT ? "5rem" : "10rem"),
            marginTop:'38px'
          }}
        >
          <a href={process.env.REACT_APP_BTN_COMIENZA_GRATIS} style={styles.buttonStarted}>Pruébalo gratis</a>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12" style={{marginBottom:'0rem'}}></div>
      </div>
    </div> */
  );
};
