import React from "react";
import { Slide } from "react-reveal";

import misimbolo from "../../assets/img/section-developer/simbolo_dev.svg";
import candado from "../../assets/img/section-developer/candado_dev.svg";
import firma from "../../assets/img/section-developer/firma_dev.svg";
import imgLeft1 from "../../assets/img/section-developer/subLeft.svg";
import vcode from "../../assets/img/section-developer/img-code-java.svg";
import code1 from "../../assets/img/section-developer/code1.svg";
import cuadritos from "../../assets/img/section-developer/cuadritos.svg";
import robot from "../../assets/img/section-developer/robot.svg";
import imgLogo from "../../assets/img/section-developer/logo_dev.svg";

export const SectionDevBoxs = () => {
  return (
    <>
      <Slide bottom>
        <div className="container-fluid container-boxs">
          <div className="row d-flex align-items-center">
            <div className="col-sm-11 col-9 mx-auto">
              <div className="row container-cards g-0">
                <div className="col-sm-4 col-12 miCard1">
                  <div className="card box1">
                    <div className="card-body text-center">
                      <h5 className="card-title">Integra fácil </h5>
                      <img
                        src={misimbolo}
                        className="card-img-center"
                        alt="image"
                      />
                      <p className="card-text card-text-box text-lg">
                        APIs REST compatibles y listas <br />
                        para usar desde cualquier
                        <br />
                        plataforma móvil o web
                      </p>
                      <p className="card-text card-text-box text-sm">
                        APIs REST compatibles y listas <br />
                        para usar desde cualquier
                        <br />
                        plataforma móvil o web
                      </p>
                      <p className="card-text text-xs">
                        APIs REST compatibles
                        <br /> y listas para usar desde cualquier
                        <br />
                        plataforma móvil o web
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-12 miCard2">
                  <div className="card box2">
                    <div className="card-body text-center">
                      <h5 className="card-title">Firma embebida</h5>
                      <img
                        src={firma}
                        className="card-img-center-2"
                        alt="image"
                      />
                      <div className="text-lg">
                        <p className="card-text">
                          Crea y realiza  tus procesos
                          <br /> por medio de nuestro
                          <br />
                          aplicativo inteligente o a través
                          <br /> de DecSigner
                        </p>
                      </div>
                      <div className="text-sm">
                        <p className="card-text">
                          Crea y realiza  tus procesos
                          <br /> por medio de nuestro
                          <br />
                          aplicativo inteligente o<br /> a través de DecSigner
                        </p>
                      </div>
                      <div className="text-xs">
                        <p className="card-text">
                          Crea y realiza  tus procesos por medio <br /> de
                          nuestro aplicativo inteligente o a <br /> través de
                          DecSigner
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-12 miCard3">
                  <div className="card box3">
                    <div className="card-body text-center">
                      <h5 className="card-title">APIs Seguras</h5>
                      <img
                        src={candado}
                        className="card-img-center-candado"
                        alt="image"
                      />
                      <p className="card-text card-text-box text-lg">
                        APIs con los más altos
                        <br /> estándares de seguridad
                        <br /> en la industria
                      </p>
                      <p className="card-text card-text-box text-sm">
                        APIs con los más altos
                        <br /> estándares de seguridad
                        <br /> en la industria
                      </p>
                      <p className="card-text text-xs-box">
                        APIs con los más altos estándares de <br />
                        seguridad en la industria
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-dec g-0 container-image">
            <div className="col-sm-11 col-12 mx-auto fondo-gris">
              <div class="row d-flex align-items-center">
                <div className="col-lg-7 col-sm-6 order-sm-0 col-12 order-1">
                  <img
                    src={imgLeft1}
                    alt=""
                    className="img img-fluid img-dispositivos"
                  ></img>
                </div>
                <div className="col-lg-5 col-sm-6 p-h col-12 order-sm-1 order-0">
                  <h2 className="devBanner-titulo-2 m-l">
                    <b>Potencia tu sistema o</b> <br />
                    aplicación integrando
                    <br />
                    <b>Dec en tus flujos</b>
                  </h2>
                  <p className="devBanner-parrafo2 text-lg">
                    Contamos con las más potentes APIs Rest para generar
                    <br />
                    tus procesos, enviar notificaciones, crear firmas y unir
                    <br />
                    a tu comunidad digital. Todos los beneficios de Dec
                    <br />y Easy Sign al alcance de un API.
                  </p>
                  <p className="devBanner-parrafo2 text-sm m-l">
                    Contamos con las más potentes APIs Rest <br /> para generar
                    tus procesos, enviar notificaciones,
                    <br /> crear firmas y unir a tu comunidad digital. <br />
                    Todos los beneficios de Dec y Easy Sign <br /> al alcance de
                    un API.
                  </p>
                  <p className="devBanner-parrafo2 text-xs m-l text-center">
                    Contamos con las más potentes APIs Rest para <br /> generar
                    tus procesos, enviar notificaciones, crear <br /> firmas y
                    unir a tu comunidad digital. <br />
                    Todos los beneficios de Dec y Easy Sign <br /> al alcance de
                    un API.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slide>
      <div className="container-fluid container-create">
        <div className="row row row-dec g-0 mt  ">
          <div className="col-11 mx-auto ">
            <div className="row d-flex align-items-center">
              <Slide left>
                <div className="col-sm-5 col-12 p-h ">
                  <h2 className="devBanner-titulo2 text-lg-create">
                    <b>
                      Creadas por <br />
                      desarrolladores,
                    </b>
                    <br />
                    para desarrolladores <br />
                    que buscan lo mejor
                  </h2>
                  <h2 className="devBanner-titulo2 text-xs-create">
                    <b>Creadas por desarrolladores,</b>
                    <br />
                    <b>para desarrolladores</b> que
                    <br />
                    buscan lo mejor
                  </h2>
                  <p className="devBanner-parrafo2 text-lg">
                    ¡La mejor experiencia de uso e integración!<br></br>
                    Nos ajustamos al dinamismo de tu<br></br>
                    empresa o negocio y diseñamos los<br></br>
                    mejores procesos para ti.<br></br>
                    <a
                      href={process.env.REACT_APP_BTN_LEER_DOCUMENTACION}
                      className="btn btn-success LeerDocumentacion"
                    >
                      Leer documentación
                    </a>
                     
                  </p>
                  <p className="devBanner-parrafo2 text-sm">
                    ¡La mejor experiencia de uso e<br /> integración! Nos
                    ajustamos al dinamismo <br /> de tu empresa o negocio y
                    diseñamos los
                    <br /> mejores procesos para ti.
                    <a
                      href={process.env.REACT_APP_BTN_LEER_DOCUMENTACION}
                      className="btn btn-success LeerDocumentacion"
                    >
                      Leer documentación
                    </a>
                     
                  </p>
                  <p className="devBanner-parrafo2 text-xs text-center">
                    ¡La mejor experiencia de uso e integración! <br />
                    Nos ajustamos al dinamismo de tu empresa o negocio
                    <br /> y diseñamos los mejores procesos para ti.
                  </p>
                </div>
              </Slide>
              <Slide right>
                <div className="col-sm-7 col-12 text-end container-img-code-java ">
                  <img
                    src={vcode}
                    alt=""
                    className="img img-fluid img-code "
                  ></img>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid backgraund-sm container-iconos-sm"></div>
      <div className="container-fluid backgraund-sm container-iconos-xs"></div>
      <Slide left>
        <div className="row row-dec g-0 container-iconos-sm row-iconos">
          <div className="col-10 mx-auto">
            <div class="row row-container-iconos">
              <div className="col-5 text-start p-h">
                <img
                  src={code1}
                  alt=""
                  className="img img-fluid iconoLeft"
                ></img>
                <h5 className="hLeft">DevZone</h5>
                <p className="pIconos">
                  Consulta la documentación para<br></br>desarrolladores
                  disponible, completa
                  <br /> y fácil de entender.  
                </p>
              </div>
              <div className="col-5 text-start px-right">
                <img
                  src={cuadritos}
                  alt=""
                  className="img img-fluid iconoRight "
                ></img>
                <h5 className="hRight">Construye</h5>
                <p className="pIconos">
                  Adapta Dec a tu flujo, crea
                  <br /> aplicaciones y soluciones. <br></br>
                  <b> ¡Tú eliges cómo y cuándo!</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Slide>

      <div className="container-fluid container-iconos-lg">
        <Slide left>
          <div className="row g-0 row-dec ">
            <div className="col-7">
              <div className="row miconos">
                <div className="col-6 container-dev-zone">
                  <img
                    src={code1}
                    alt=""
                    className="img img-fluid iconoLeft "
                  ></img>
                  <h5 className="hLeft">DevZone</h5>
                  <p className="pIconos">
                    Consulta la documentación para<br></br>desarrolladores
                    disponible,<br></br>
                    completa y fácil de entender.  
                  </p>
                </div>
                <div className="col-6" style={{ padding: "1%" }}>
                  <img
                    src={cuadritos}
                    alt=""
                    className="img img-fluid iconoRight "
                  ></img>
                  <h5 className="hRight">Construye</h5>
                  <p className="pIconos">
                    Adapta Dec a tu flujo,<br></br>
                    crea aplicaciones y soluciones. <br></br>
                    <b> ¡Tú eliges cómo y cuándo!</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </div>
      <Slide left>
        <div className="container-fluid container-iconos-xs">
          <div className="row g-0 row-dec ">
            <div className="col-11 mx-auto">
              <div className="row miconos">
                <div className="col-12 container-dev-zone text-center">
                  <img
                    src={code1}
                    alt=""
                    className="img img-fluid iconoLeft "
                  ></img>
                  <h5 className="hLeft">DevZone</h5>
                  <p className="pIconos">
                    Consulta la documentación para
                    <br /> desarrolladores disponible, completa y fácil
                    <br /> de entender. 
                  </p>
                </div>
                <div className="col-12 text-center">
                  <img
                    src={cuadritos}
                    alt=""
                    className="img img-fluid iconoRight "
                  ></img>
                  <h5 className="hRight">Construye</h5>
                  <p className="pIconos">
                    Adapta Dec a tu flujo, crea aplicaciones y<br /> soluciones.{" "}
                    <b>¡Tú eliges cómo y cuándo!</b>
                  </p>
                </div>
                <div className="col-12 text-center">
                  <a
                    href={process.env.REACT_APP_BTN_LEER_DOCUMENTACION}
                    className="btn btn-success LeerDocumentacion"
                  >
                    Leer documentación
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slide>
      <div className="container-fluid container-full-bot">
        <div className="row row row-dec g-0 mx-auto  d-flex align-items-center ">
          <Slide left>
            <div className="col-sm-5 col-12 order-sm-0 order-1  bot-container-xs">
              <img src={robot} className="img img-fluid bot"></img>
            </div>
          </Slide>
          <Slide right>
            <div className="col-sm-7 col-12 order-sm-1 order-0">
              <div className="row d-flex align-items-center">
                <div className="col-12 text-center">
                  <img
                    src={imgLogo}
                    className="img img-fluid logo-dec-footer"
                  ></img>
                </div>
                <div className="col-12 text-center">
                  <p className="devBanner-parrafo text-lg">
                    Potencia al máximo tus aplicaciones o procesos de negocio.
                  </p>
                  <p className="devBanner-parrafo text-sm">
                    <b>
                      Potencia al máximo tus aplicaciones
                      <br /> o procesos de negocio.
                    </b>
                  </p>
                  <p className="devBanner-parrafo-2 text-xs">
                    Potencia al máximo tus aplicaciones
                    <br /> o procesos de negocio.
                  </p>
                </div>
                <div className="col-12 text-center container-backgraund">
                  <p className="text-center text-backgraund ">
                    ¿Listo para comenzar?
                  </p>
                </div>
                <div className="col-12 text-center">
                  <a
                    href={process.env.REACT_APP_BTN_COMIENZA_GRATIS}
                    className="btn-pruebalo-2"
                  >
                    Pruébalo gratis
                  </a>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </>
  );
};
