import  { useEffect, useState } from "react";

const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const xs= 425;
  const sm= 576;
  const md= 768;
  const lg= 992;
  const xl= 1200;
  const  xxl= 140;
  const breakpoint = 640;
  const breakpointT = 768;
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { width, breakpoint, breakpointT,xs,sm,md,lg,xl,xxl };
};

export default useViewport;
