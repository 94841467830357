import React from "react";
import "../../styles/footer.css";
import logoFooter from "../../assets/img/footer/logoDecFooter.svg";

export const Footer = () => {
  return (
      <footer>
        <div className="body-footer">
          <div className="row g-0">
            <div className="col-lg-5 col-sm-8 col-9 mx-auto">
              <div className="row g-0">
                <div className="col text-start">
                  <a
                    href={process.env.REACT_APP_FOOTER_SOPORTE}
                    style={{
                      textDecoration: "underline !important",
                      borderBottom: "1px solid #ffffff9e",
                    }}
                  >
                    Soporte
                  </a>
                </div>
                <div className="col text-start">
                  <a
                    href={process.env.REACT_APP_FOOTER_PRIVACIDAD}
                    style={{
                      textDecoration: "none !important",
                      borderBottom: "1px solid #ffffff9e",
                    }}
                  >
                    Privacidad
                  </a>
                </div>
                <div className="col text-center">
                  <a
                    href={process.env.REACT_APP_FOOTER_DESARROLLADORES}
                    style={{
                      textDecoration: "none !important",
                      borderBottom: "1px solid #ffffff9e",
                    }}
                  >
                    Desarrolladores
                  </a>
                </div>
                <div className="col text-center">
                  <a
                    href={process.env.REACT_APP_FOOTER_LEGAL}
                    style={{
                      textDecoration: "none !important",
                      borderBottom: "1px solid #ffffff9e",
                    }}
                  >
                    Legal
                  </a>
                </div>
              </div>
            </div>

            <div className="mt-4 col-xxl-12 col-xl-12 col-md-12">
              <div className="text-center">
                <a href={process.env.REACT_APP_LOGO_FOOTER}>
                  <img src={logoFooter} alt="footer" className="img-fotter" />
                </a>
              </div>
            </div>
            <div className="text-center mt-4 col-xxl-12 col-xl-12 col-md-12">
              <p>Copyright 2021. Todos lo derechos reservados .</p>
            </div>
          </div>
        </div>
      </footer>
  );
};
