import React from "react";
import { Slide } from "react-reveal";
import table from "../../assets/img/section-sign/tablet.svg";
import ruc from "../../assets/img/section-sign/ruc.svg";
import arrow from "../../assets/img/section-sign/arrow-complete.svg";
import arrowMobile from "../../assets/img/section-sign/imagen_02_page_03.svg";
import { useMediaQuery } from "react-responsive";

export const SectionSignRuc = () => {
  const isMobile = useMediaQuery({ maxWidth: 425 });
  return (
    <>
      <div className="container-fluid" id="sign-ruc">
        <div className="row row-dec g-0">
          <div className="col-lg-8 col-sm-10 mx-auto ruc-container ">
            <div className="row d-flex align-items-center">
              <Slide left>
                <div className="col-lg-5 col-sm-6 col-12 order-sm-0 order-1">
                  <h1 className="title-lg">
                    <b>Tu firma electrónica</b>
                    <br /> segura y confiable 
                  </h1>
                  <h1 className="title-xs text-center">
                    <b>Tu firma electrónica segura y confiable </b>
                  </h1>
                  <p className="text-lg">
                    Las firmas de Easy Sign
                    <br /> son legales, intransferibles
                    <br /> y validadas con tu identidad. 
                  </p>
                  <p className="text-sm">
                    Las firmas de <b>Easy Sign </b>
                    son legales, <br /> intransferibles y validadas con tu{" "}
                    <br />
                    identidad. 
                  </p>
                  <p className="text-xs text-center">
                    Las firmas de <b>Easy Sign </b>
                    son legales, <br /> intransferibles y validadas con tu
                    identidad. 
                  </p>
                </div>
              </Slide>
              <Slide right>
                <div className="col-lg-7 col-sm-6 text-sm-end text-center img-container col-12 order-sm-1 order-0">
                  <img src={table} alt="" />
                </div>
              </Slide>
            </div>
            <div className="row d-flex align-items-top ruc-header">
              <Slide left>
                <div className="col-lg-5  col-sm-5 text-sm-start text-center img-container-ruc">
                  <img src={ruc} alt="" />
                </div>
              </Slide>
              <Slide right>
                <div className="col-lg-7 col-sm-7">
                  <h1 className="text-lg">
                    <b>Easy Sign</b> <br />
                    se ajusta a ti 
                  </h1>
                  <h1 className="text-xs text-center">
                    <b>Easy Sign se ajusta a ti </b>
                  </h1>
                  <p className="text-lg">
                    Somos la mejor opción para
                    <b>
                      &nbsp;personas
                      <br /> naturales, jurídicas  o naturales con RUC
                    </b>
                    <br /> durante sus procesos de firmado electrónico.
                  </p>
                  <p className="text-xs text-center">
                    Somos la mejor opción para
                    <b>
                      &nbsp;personas
                      <br /> naturales, jurídicas  o naturales con RUC
                    </b>
                    <br /> durante sus procesos de firmado electrónico.
                  </p>
                  <p className="text-lg">
                    ¡También aceptamos las firmas acreditadas
                    <br /> que ya posees! Súbelas, úsalas y guárdalas en
                    <br /> la nube.
                  </p>
                  <p className="text-xs text-center">
                    ¡También aceptamos las <b>firmas acreditadas</b>
                    <br /> que ya posees! Súbelas, úsalas y guárdalas
                    <br /> en la nube.
                  </p>
                  <h1 className="text-sm">
                    <b>Easy Sign</b> se ajusta a ti 
                  </h1>
                  <p className="text-sm" style={{ marginTop: "-2px" }}>
                    Somos la mejor opción para
                    <b>
                      &nbsp;personas
                      <br /> naturales, jurídicas  o naturales con RUC
                    </b>
                    <br /> durante sus procesos de firmado <br />
                    electrónico.
                    <br />
                    ¡También aceptamos las <b>firmas acreditadas</b>
                    <br /> que ya posees! Súbelas, úsalas y guárdalas en
                    <br /> la nube.
                  </p>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row g-0">
          <div className="col text-end">
            <img
              className="img-arrow"
              src={isMobile ? arrowMobile : arrow}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
    /*    <section
      id="sign-ruc"
      className="d-flex align-items-center"
      style={{
        top: width < breakpoint ? "-150px" : "",
        paddingBottom: width < breakpoint ? "10px" : "",
        paddingLeft: width < breakpoint ? "6%" : "",
        paddingRight: width < breakpoint ? "4%" : "",
      }}
    >
      <Container fluid className="container-header">
        <LightSpeed right>
          <Row>
            <Col
              className="mt-2"
              md={
                width > breakpoint && width < breakpointT
                  ? { span: 12, offset: 1 }
                  : { span: 9, offset: 3 }
              }
            >
              <Row>
                <Col
                  lg={{ span: 5, order: 1 }}
                  md={{ span: 5, order: 1 }}
                  sm={{ span: 5, order: 1 }}
                  xs={{ span: 12, order: 2 }}
                  className="pt-3 d-lg-flex flex-lg-column justify-content-center align-items-stretch"
                >
                  <div
                    className="text-center text-lg-center"
                    style={{ marginLeft: width < breakpoint ? "0" : "10%" }}
                  >
                    <h2
                      className={
                        width < breakpoint ? "text-center" : "text-left"
                      }
                      style={{
                        fontSize:
                          width < breakpoint
                            ? "20px"
                            : width > breakpoint && width < breakpointT
                            ? "26px"
                            : "",
                      }}
                    >
                      Tu firma electrónica{" "}
                      {width < breakpoint && "segura y confable"}
                    </h2>
                    {width < breakpoint ? (
                      ""
                    ) : (
                      <h2
                        className="text-left"
                        style={{
                          fontSize:
                            width < breakpoint
                              ? "20px"
                              : width > breakpoint && width < breakpointT
                              ? "26px"
                              : "",
                          fontFamily: "MYRIADPROREGULAR",
                        }}
                      >
                        segura y confable
                      </h2>
                    )}
                    <p
                      className={
                        width < breakpoint ? "text-center" : "text-left"
                      }
                      style={{
                        display:
                          width > breakpoint && width < breakpointT && "none",
                        fontSize:
                          width < breakpoint
                            ? "18px"
                            : width > breakpoint && width < breakpointT
                            ? "12px"
                            : "",
                        marginBottom: 0,
                      }}
                    >
                      Las firmas de Easy Sign{" "}
                      {width < breakpoint &&
                        "son legales, intransferibles y validadas con tu identidad."}
                    </p>
                    {width < breakpoint ? (
                      ""
                    ) : (
                      <p
                        className="text-left"
                        style={{
                          display:
                            width > breakpoint && width < breakpointT && "none",
                          fontSize:
                            width < breakpoint
                              ? "15px"
                              : width > breakpoint && width < breakpointT
                              ? "12px"
                              : "",
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                      >
                        son legales, intransferibles
                      </p>
                    )}
                    {width < breakpoint ? (
                      ""
                    ) : (
                      <p
                        className="text-left"
                        style={{
                          display:
                            width > breakpoint && width < breakpointT && "none",
                          fontSize:
                            width < breakpoint
                              ? "15px"
                              : width > breakpoint && width < breakpointT
                              ? "12px"
                              : "",
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                      >
                        y validadas con tu identidad.
                      </p>
                    )}
                    <p
                      className="text-left"
                      style={{
                        display:
                          width > breakpoint && width < breakpointT
                            ? "block"
                            : "none",
                        fontSize:
                          width < breakpoint
                            ? "15px"
                            : width > breakpoint && width < breakpointT
                            ? "17px"
                            : "",
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                    >
                      Las firmas de <b>Easy Sign</b> son legales,
                      intransferibles y validadas con tu identidad.
                    </p>
                  </div>
                </Col>
                <Col
                  lg={{ span: 4, order: 2 }}
                  md={{ span: 4, order: 2 }}
                  sm={{ span: 4, order: 2 }}
                  xs={{ span: 12, order: 1 }}
                  className="order-1 order-lg-2 hero-img aos-init animate__animated animate__fadeInUp"
                >
                  <div className="text-center">
                    {width < breakpoint ? (
                      <img src={table} style={{ width: "30%" }} />
                    ) : width > breakpoint && width < breakpointT ? (
                      <img src={table} style={{ width: "60%" }} />
                    ) : (
                      <Image src={table} fluid />
                    )}
                  </div>
                </Col>
              </Row>
              <Row
                style={{
                  paddingTop: "3%",
                  marginTop:
                    width > breakpoint && width < breakpointT ? "0" : "8%",
                }}
              >
                <Col
                  lg={3}
                  md={3}
                  sm={3}
                  className="hero-img aos-init animate__animated animate__fadeInUp"
                >
                  <div className="text-left" style={{ marginLeft: "33px" }}>
                    {width < breakpoint ? (
                      <img
                        src={ruc}
                        style={{
                          width: "40%",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                    ) : width > breakpoint && width < breakpointT ? (
                      <img
                        src={ruc}
                        style={{
                          width: "100%",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          paddingTop: "15%",
                        }}
                      />
                    ) : (
                      <Image src={ruc} fluid />
                    )}
                  </div>
                </Col>
                <Col
                  lg={6}
                  md={7}
                  sm={5}
                  className="pt-3 d-lg-flex flex-lg-column justify-content-center align-items-stretch"
                >
                  <div
                    className="text-center text-lg-center"
                    style={{
                      marginLeft:
                        width < breakpoint
                          ? "0"
                          : width > breakpoint && width < breakpointT
                          ? "20%"
                          : "10%",
                    }}
                  >
                    <h2
                      className={
                        width < breakpoint ? "text-center" : "text-left"
                      }
                      style={{
                        fontSize:
                          width < breakpoint
                            ? "20px"
                            : width > breakpoint && width < breakpointT
                            ? "26px"
                            : "",
                      }}
                    >
                      Easy Sing {width < breakpoint && " se ajusta a ti"}{" "}
                      {width > breakpoint &&
                        width < breakpointT &&
                        " se ajusta a ti"}
                    </h2>
                    <p
                      className="text-left"
                      style={{
                        display:
                          width > breakpoint && width < breakpointT
                            ? "block"
                            : "none",
                        fontSize:
                          width < breakpoint
                            ? "17px"
                            : width > breakpoint && width < breakpointT
                            ? "17px"
                            : "",
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                    >
                      Somos la mejor opción para{" "}
                      <b> personas naturales, jurídicas o naturales con RUC </b>{" "}
                      durante sus procesos de firmado<br></br> electrónico.
                      <br />
                      ¡También aceptamos las <b>firmas acreditadas</b> que ya
                      posees! Súbelas, úsalas y guárdalas <br /> en la nube.
                    </p>
                    {width < breakpoint ? (
                      ""
                    ) : (
                      <h2
                        className="text-left"
                        style={{
                          display:
                            width > breakpoint && width < breakpointT && "none",
                          fontSize:
                            width < breakpoint
                              ? "20px"
                              : width > breakpoint && width < breakpointT
                              ? "16px"
                              : "",
                          fontFamily: "MYRIADPROREGULAR",
                        }}
                      >
                        se ajusta a ti
                      </h2>
                    )}
                    <p
                      className={
                        width < breakpoint ? "text-center" : "text-left"
                      }
                      style={{
                        display:
                          width > breakpoint && width < breakpointT && "none",
                        fontSize:
                          width < breakpoint
                            ? "18px"
                            : width > breakpoint && width < breakpointT
                            ? "12px"
                            : "",
                        marginBottom: "10px",
                      }}
                    >
                      Somos la mejor opción para{" "}
                      <b>
                        personas{" "}
                        {width < breakpoint &&
                          "naturales, jurídicas  o naturales con RUC"}{" "}
                      </b>{" "}
                      {width < breakpoint &&
                        " durante sus procesos de firmado electrónico."}
                    </p>
                    {width < breakpoint ? (
                      ""
                    ) : (
                      <p
                        className="text-left"
                        style={{
                          display:
                            width > breakpoint && width < breakpointT && "none",
                          fontSize:
                            width < breakpoint
                              ? "17px"
                              : width > breakpoint && width < breakpointT
                              ? "12px"
                              : "",
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                      >
                        <b>naturales, jurídicas o naturales con RUC</b>
                      </p>
                    )}
                    {width < breakpoint ? (
                      ""
                    ) : (
                      <p
                        className="text-left"
                        style={{
                          display:
                            width > breakpoint && width < breakpointT && "none",
                          fontSize:
                            width < breakpoint
                              ? "18px"
                              : width > breakpoint && width < breakpointT
                              ? "12px"
                              : "",
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                      >
                        durante sus procesos de firmado electrónico.
                      </p>
                    )}
                    {width < breakpoint ? "" : <br></br>}
                    <p
                      className={
                        width < breakpoint ? "text-center" : "text-left"
                      }
                      style={{
                        display:
                          width > breakpoint && width < breakpointT && "none",
                        fontSize:
                          width < breakpoint
                            ? "18px"
                            : width > breakpoint && width < breakpointT
                            ? "12px"
                            : "",
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                    >
                      ¡También aceptamos las <b>firmas acreditadas</b>{" "}
                      {width < breakpoint &&
                        " que ya posees! Súbelas, úsalas y guárdalas en la nube."}
                    </p>
                    {width < breakpoint ? (
                      ""
                    ) : (
                      <p
                        className="text-left"
                        style={{
                          display:
                            width > breakpoint && width < breakpointT && "none",
                          fontSize:
                            width < breakpoint
                              ? "17px"
                              : width > breakpoint && width < breakpointT
                              ? "12px"
                              : "",
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                      >
                        que ya posees! Súbelas, úsalas y guárdalas en
                      </p>
                    )}
                    {width < breakpoint ? (
                      ""
                    ) : (
                      <p
                        className="text-left"
                        style={{
                          display:
                            width > breakpoint && width < breakpointT && "none",
                          fontSize:
                            width < breakpoint
                              ? "17px"
                              : width > breakpoint && width < breakpointT
                              ? "12px"
                              : "",
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                      >
                        la nube.
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </LightSpeed>
        {width < breakpoint ? (
          <div style={{ padding: "70px" }}></div>
        ) : (
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <LightSpeed right>
              <div className="text-right arrow-left">
                <Image
                  src={arrow}
                  fluid
                  style={{
                    width:
                      width > breakpoint && width < breakpointT
                        ? "77%"
                        : "100%",
                  }}
                />
              </div>
            </LightSpeed>
          </Col>
        )}

        <Row />
      </Container>
    </section> */
  );
};
