import React from "react";
import "animate.css/animate.css";
import leftHomeImg  from '../../assets/img/img-left.svg';
import Image from 'react-bootstrap/Image';
import leftHomeImgMobil  from '../../assets/img/main-img-mobil.svg';
import { useMediaQuery } from "react-responsive";

export const SectionsHeader = () => {
  const isMobile = useMediaQuery({ maxWidth: 425 });
  return (
    <div id="container-1" className="container-fluid">
      <div className="row row-dec g-0">
        <div
          className="col-lg-5 col-md-5 col-sm-5 col-xs-12  animate__animated animate__fadeInUp"
       /*    style={{ marginTop: width < breakpoint ? "2rem" : "117px" }} */
        >
             <h1><b>Firma documentos</b> <br /> electrónicos en tu <br /> <b>portafolio digital</b> </h1>
              <a href={process.env.REACT_APP_BTN_COMIENZA_GRATIS} className="button-header">Prúebalo gratis</a>
        </div>
        <div
          className="col-lg-7 col-md-7 col-sm-7 col-xs-12 animate__animated animate__fadeInUp"
          style={{ marginTop:isMobile ? "0rem" : "80px" }}
        >
         {
            isMobile? <Image src={leftHomeImgMobil} className="img-main"  />:  <Image src={leftHomeImg}  />
         }
        </div>
      </div>
    </div>
  );
};
