import React from "react";
import "../../src/styles/plans.css";
import { SectionPlansHeader } from "./plans-sections/SectionPlansHeader";
import { SectionPlansBody } from "./plans-sections/SectionPlansBody";

export const Plans = () => {
  return (
    <>
      <SectionPlansHeader />
      <SectionPlansBody/>
    </>
  );
};
