import React from "react";
import "../styles/sign.css";

import { SectionHeaderSign } from "./sign-sections/SectionHeaderSign";
import { SectionSignInfo } from "./sign-sections/SectionSignInfo";
import { SectionSignRuc } from "./sign-sections/SectionSignRuc";
import { SectionSignFooter } from "./sign-sections/SectionSignFooter";

export const Sign = () => {
  return (
    <>
      <SectionHeaderSign />
      <SectionSignInfo />
      <SectionSignRuc />
      <SectionSignFooter />
    </>
  );
};
